import Cookies from "js-cookie";
import { Navigate, useLocation } from "react-router-dom";
// import store from "../store/app.store";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    let location = useLocation();

    // Récupérer le token depuis les cookies
    const token = Cookies.get('token'); 

    // Vérifier si le token existe
    if (!token) {
        // Si pas de token, rediriger vers la page de connexion
        return (
            <Navigate 
                to={`/signin?redirect=${location.pathname}${location.search}`} 
                state={{ from: location }} 
            />
        );
    }

    // Si le token est présent, retourner le composant enfant (la route protégée)
    return children;
};

export default ProtectedRoute;
