interface Props {
    date: string;
}

const FormattedDate: React.FC<Props> = ({date}) => {
    const propsDate = new Date(date);
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
  }).format(propsDate);

    return <span>{formattedDate}</span>
}

export default FormattedDate;