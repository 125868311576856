import axios from 'axios';
import TokenService from './TokenService';


// const API_URL = process.env.REACT_APP_API_URL; 
const API_URL = "https://www.mydeepdev.com"; 



const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


// axiosInstance.interceptors.request.use((config:any)=>{
//   console.log(config)  
//   return config
// })

const  tokenCurrent  = TokenService.getToken()


const apiService = {
  url : API_URL,

  login: async (email:string, password:string) => {
    const response = await axiosInstance.post(`${API_URL}/auth/connexion`, {
      email,
      password,
    });
    return response.data;
  },

  getProtectedUsers: async (token:string) => {
    const response = await axiosInstance.get('/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  getAllPosts: async () => {
    const response = await axiosInstance.get('/posts');
    return response.data;
  },

  test: async () => {
    const response = await axiosInstance.get(`${API_URL}/auth/test` );
    return response.data;
  },

  createPost: async (postData:string, token:string) => {
    const response = await axiosInstance.post('/posts', postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  validateToken: async (token: string) => {
    const response = await axios.get(`${API_URL}/validate-token`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; 
  },

  createEvent: async(formData: FormData)=>{
    try {
      const response = await axios.post(`${API_URL}/event`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', 
          Authorization: `Bearer ${tokenCurrent}`,
        },
      });

      return response.data; 
    } catch (error) {
      console.error('Error creating event:', error);
      throw new Error('Failed to create event');
    }
  },

  getEvents: async () => {
    try {
      const response = await axios.get(`${API_URL}/event`, {});
      return response.data; 
      
    } catch (error) {
      throw new Error('Failed to get Event');
    }

  },

  getEventById: async (id: number) => {
    const response = await axios.get(`${API_URL}/event/${id}`, {});
    return response.data;
  },

  updateEvent: async (eventId: number, payload: FormData) => {
    const response = await axios.patch(`${API_URL}/event/${eventId}`, payload);
    return response.data;
  },

  deleteEvent: async (id: number) => {
    try {
      const response = await axios.delete(`${API_URL}/event/${id}`,{
        headers: {
          Authorization: `Bearer ${tokenCurrent}`,
        },
      });
      return response.data; 
    } catch (error) {
      console.error(error); 
      throw new Error('Failed to delete Event');
    }
  }

};

export default apiService;
