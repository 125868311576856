import React, { useEffect, useState } from 'react';
import { Button, Center, Container, Flex, Group, Stack, Text, TextInput } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Image } from '@mantine/core';
import apiService from '../../../shared/services/apiService';
import TokenService from '../../../shared/services/TokenService';
import Cookies from 'js-cookie';


const LoginContainer = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [errorMail, setErrorMail] = useState<string | null>(null);
    const token = Cookies.get('token');




    useEffect(() => {
        const validateToken = async () => {
            if (token) {
                try {
                    await apiService.validateToken(token);
                    navigate('/dashboard');
                } catch (error) {
                    console.error('Token is invalid or expired', error);
                }
            }
        };

        validateToken();
    }, [token, navigate]);

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let valid = true;

        if (!validateEmail(email)) {
            setErrorMail("L'email est invalide.");
            valid = false;
        } else {
            setErrorMail(null); 
        }

        if (valid) {
            
            try {
                const response = await apiService.login(email, password);
                const token = response.token;

                TokenService.setToken(token)
                setError(null);
                navigate('/dashboard'); 
            } catch (err) {
                setError('Erreur lors de la connexion.');
            }
        }
    };
    return (
        <Container fluid style={{ background: 'linear-gradient(to bottom, white, #A71D78)', height: '100vh' }}>
            <Flex
                style={{ width: '100%', paddingTop: '100px' }}
                gap="md"
                justify="flex-start"
                align="center"
                direction="row"
                wrap="nowrap"
            >
                <Container fluid p='0' style={{ width: 400, backgroundColor: '#fff' }}>
                    <Flex
                        mih={50}
                        gap="lg"
                        justify="center"
                        align="center"
                        direction="column"
                        wrap="wrap-reverse"
                    >
                        <Group style={{ width: '100%', height: 300, paddingTop: "50px", backgroundColor: '#A71D78', display: 'flex', justifyContent: 'center', alignItems: 'start', clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0 100%)' }}>
                            <Center style={{ flexDirection: 'column' }}>
                                <Image
                                    radius="md"
                                    src={"images/logo.png"}
                                    alt="Logo"
                                    w="60"
                                />
                                <Text
                                    style={{ fontSize: '40px', fontWeight: 900, color: 'white' }}
                                >
                                    Cité des cultures 
                                </Text>
                                <Text style={{ color: '#F2A402' }} >
                                    Backoffice
                                </Text>
                            </Center>
                        </Group>
                        <Group >
                            <form onSubmit={handleSubmit}>
                                <Stack gap="md" pb="50px">
                                    <TextInput
                                        type="email"
                                        placeholder="Email"
                                        styles={{
                                            input: {
                                                width: '300px',
                                                border: 'none',
                                                borderBottom: '1px solid #A71D78',
                                                borderRadius: 0,
                                                padding: '0.5rem 0',
                                            },
                                            root: {
                                                width: '100%',
                                            },
                                        }}
                                        value={email}
                                        onChange={(e:any) => setEmail(e.target.value)}
                                        error={errorMail}
                                    />

                                    <TextInput
                                        placeholder="Password"
                                        type="password"
                                        styles={{
                                            input: {
                                                width: '100%',
                                                border: 'none',
                                                borderBottom: '1px solid #A71D78',
                                                borderRadius: 0,
                                                padding: '0.5rem 0',
                                            },
                                            root: {
                                                width: '100%',
                                            },
                                        }}
                                        value={password}
                                        onChange={(e:any) => setPassword(e.target.value)}
                                        error={error}
                                    />

                                    <Button
                                        type="submit"
                                        fullWidth
                                        size="md"
                                        color='#A71D78'
                                        mt='15px'

                                    >
                                        Connecter  
                         
                                    </Button>
                                </Stack>
                            </form>
                        </Group>
                    </Flex>
                </Container>
            </Flex>
        </Container>


    );
};

export default LoginContainer;

