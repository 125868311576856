import {  createBrowserRouter, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import MainWrapper from "../shared/Layout/MainWrapper";
import DashboardContainer from "../pages/Dashboard/container/dashboard.container";
import UsersContainer from "../pages/Users/containers/users.container";
import SettingContainer from "../pages/Setting/container/setting.container";
import AlauneContainer from "../pages/Alaune/container/alaune.container";
import EventsContainer from "../pages/Evenements/container/events.container";
import EventEditContainer from "../pages/Evenements/container/events.edit.container";
import Roomcontainer from "../pages/Room/containers/room.container";
import RoomEditContainer from "../pages/Room/containers/room.edit.container";
import RentalCotanainer from "../pages/Rental/containers/rental.container";
import FormsRentalContainer from "../pages/Rental/containers/forms.rental.container";
import NotificationContainer from "../pages/Notification/containers/notification.container";
import PartenerContainers from "../pages/Parteners/containers/partener.containers";
import LoginContainer from "../pages/Login/containers/login.container";

const MyRoutes = createBrowserRouter([

    {
        path: "/",
        element: <Navigate replace to="/dashboard" />
    },
    {
        path: "/",
        element: <ProtectedRoute><MainWrapper /></ProtectedRoute>,
        children: [
            {
                path: "dashboard",
                element: <DashboardContainer />
            },
            {
                path: "events",
                element: <EventsContainer/>,
            },
            {
                path: "events/edit",
                element: <EventEditContainer/>
            },
            {
                path: "rental",
                element: <RentalCotanainer/>
            },
            {
                path: "rental/reservation",
                element: <FormsRentalContainer/>
            },
            {
                path: "room",
                element: <Roomcontainer/>
            },
            {
                path: "room/edit",
                element: <RoomEditContainer/>
            },
            {
                path: "members",
                element: <>MEMBRES</>
            },
            {
                path: "alaune",
                element: <AlauneContainer/>
            },
            {
                path: "courses",
                element: <>COURS</>
            },
            {
                path: "notification",
                element: <NotificationContainer/>
            },
            {
                path: "finance",
                element: <>FINANCE</>
            },
            {
                path: "collaborateurs",
                element: <PartenerContainers/>
            },
            {
                path: "parametrage",
                element: <SettingContainer/>
            },
            {
                path: "utilisateurs",
                element: <UsersContainer />
            }
        ]
    },
    {
        path: "signin",
        element: <LoginContainer/>
    }
]);

export default MyRoutes;