import { Button, FileInput, Grid, Group, Select, Textarea, TextInput } from "@mantine/core";
import { Formik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';
import { convertToISO, formatDate } from "../../../shared/services/utilsService";
import apiService from "../../../shared/services/apiService";
import { IEvent } from "../constants/events.interface";
import { useNavigate } from "react-router-dom";

interface EventFormProps {
  event?: IEvent;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Le nom de l\'événement est requis'),
    eventType: Yup.string().required('Le type d\'événement est requis'),
    startDate: Yup.string()
        .required('La date de début est requise')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Doit être au format YYYY-MM-DD'),
    room: Yup.string().required('La salle est requise'),
    description: Yup.string().required('Le type d\'événement est requis'),
    image: Yup.string().nullable(),
});

const EventForm: React.FC<EventFormProps> = ({event}) => {
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);

  const handleImageChange = (f: File | null) => {
    setFile(f);
  };

  return <Formik
    initialValues={event ? {...event, id: undefined, startDate: formatDate(event.startDate), endDate: formatDate(event.endDate)} : { name: "", eventType: "",  startDate: "", endDate: undefined, startTime: "", endTime: "", room: "", description: "", image: null}}
    validationSchema={validationSchema}
    onSubmit={async (values, { setSubmitting }) => {
      const dateFields = ["startDate", "endDate"];
      const formData = new FormData();

      for (const key of Object.keys(values) as (keyof typeof values)[]) {
        const value = values[key];
        value && formData.append(key, dateFields.includes(key) && typeof value === "string" ? convertToISO(value) : value);
      }
      file && formData.append("image", file);
      // Array.from(formData.entries()).forEach(([key, value]) => {
      //   console.log(`${key}: ${value}`);
      //   console.log(` ===>${typeof (value)}`);
      // });

      // TODO :: Corriger la mise à jour car l'API retourne une erreur 401
      event?.id ? await apiService.updateEvent(event.id, formData) : await apiService.createEvent(formData);
      setSubmitting(false);
      navigate("/events");
    }}
    enableReinitialize
  >
    {({
      values,
      errors,
      handleChange,
      handleSubmit,
      isSubmitting,
      setFieldValue
    }) => (
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
            <TextInput
                label="Nom de l'événement"
                placeholder="Entrez le nom"
                name="name"
                value={values.name}
                onChange={handleChange}
                required
                my={15}
                error={errors.name}
            />
            <Grid>
              <Grid.Col span={{ base: 6 }}>
                <TextInput
                    label="Date de début"
                    placeholder="YYYY-MM-DD"
                    name="startDate"
                    type="date"
                    value={values.startDate}
                    onChange={handleChange}
                    required
                    my={15}
                    error={errors.startDate}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <TextInput
                    label="Heure de début"
                    placeholder="HH:MM"
                    name="startTime"
                    type="time"
                    value={values.startTime}
                    onChange={handleChange}
                    required
                    my={15}
                    error={errors.startTime}
                />
              </Grid.Col>
            </Grid>
            
            
            <TextInput
                label="Salle"
                placeholder="Entrez la salle"
                name="room"
                value={values.room}
                onChange={handleChange}
                required
                my={15}
                error={errors.room}
            />
            {!event && <FileInput
                label="Image de l'événement"
                placeholder="Téléchargez une image"
                onChange={handleImageChange}
                accept="image/png,image/jpeg,image/gif"
                my={15}
                required
            />}
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
          <Select
              label="Type d'événement"
              placeholder="Sélectionner le type"
              data={['Atelier', 'Conférence', 'Séminaire']}
              value={values.eventType}
              onChange={(value) => setFieldValue("eventType", value)}
              required
              my={15}
              error={errors.eventType}
          />
          <Grid>
            <Grid.Col span={{ base: 6 }}>
              <TextInput
                  label="Date de fin"
                  placeholder="YYYY-MM-DD"
                  name="endDate"
                  type="date"
                  value={values.endDate ?? ""}
                  onChange={handleChange}
                  my={15}
                  error={errors.endDate}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6 }}>
              <TextInput
                  label="Heure de fin"
                  placeholder="HH:MM"
                  name="endTime"
                  type="time"
                  value={values.endTime ?? ""}
                  onChange={handleChange}
                  my={15}
                  error={errors.endTime}
              />
            </Grid.Col>
          </Grid>
          <Textarea
              label="Description"
              placeholder="Entrez une description"
              name="description"
              value={values.description}
              onChange={handleChange}
              my={15}
              error={errors.description}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <Group justify="flex-end" gap="md">
              <Button mt="md" variant="filled" color="teal" type="submit">
                {isSubmitting ? "En cours d'enregistrement ": "Enregistrer"}
              </Button>
              <Button mt="md" variant="filled" color="pink" onClick={() => navigate("/events")}>
                  Annuler
              </Button>
          </Group>
      </Grid.Col>
      </Grid>
    </form>)}
  </Formik>
}

export default EventForm;