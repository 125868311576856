import React, { useState } from 'react';
import { Container } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import { IEvent } from '../constants/events.interface';
import apiService from '../../../shared/services/apiService';
import EventForm from '../components/event-form.component';
import { useQuery } from 'react-query';

const EventFormContainer: React.FC = () => {
    const [searchParams] = useSearchParams();
    const eventId = searchParams.get("id");
    const [event, setEvent] = useState<IEvent | undefined>();

    useQuery("eventToEdit", () => eventId && apiService.getEventById(parseInt(eventId)), {
        onSuccess: (data) => setEvent(data),
        enabled: !!eventId
    });

    return (
        <Container fluid>
            <h2>{eventId ? "Edition d'un événement": "Création d'un événement"}</h2>
            <EventForm event={event} />
        </Container>
    );
};

export default EventFormContainer;
